<div class="overflow-x-hidden">
	<h3 class="font-extrabold mb-6">Hiring Rules Exception</h3>
	
	<div>
		<p class="mb-4">{{ data.info }}</p>
	
		<textarea 
			rows="2"
			placeholder="Write Comment"
			[formControl]="comments"
			class="w-full border-2 rounded text-gray-600 p-4">
		</textarea>
	</div>
	
	<div class="flex justify-end mt-4">
		<button class="px-4 py-2 hover:text-black" (click)="closeDialog()">
			CANCEL
		</button>
		<button
			[ngClass]="{ 'text-blue-100 hover:text-blue-100': comments.invalid }"
			[disabled]="comments.invalid"
			(click)="confirm()"
			class="text-orange-700 hover:text-orange-900 pl-4 py-2 ml-2">
			OVERRIDE
		</button>
	</div>
</div>