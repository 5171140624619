import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { Observable } from "rxjs";
import { AssignWorker, CheckDispatchEngineRulesPayload, CheckDispatchEngineRulesResult } from "src/app/core/interfaces/assign-worker.interfaces";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { Filters } from "src/app/shared/interfaces/filters.interface";
import { getParamsFromFilters } from "src/app/shared/utils/utils";
import { API_URL } from "src/environments/settings";
import { AssignWorkerErrorDialogComponent } from "../components/assign-worker-error-dialog/assign-worker-error-dialog.component";

@Injectable({
	providedIn: "root"
})
export class DispatchService {
	
	private apiUrl: string
	
	constructor(
		private http: HttpClient,
		private env: EnvironmentService,
		private dialog: MatDialog
	) {
		this.apiUrl = this.env.getValueTransferState(API_URL);
	}

	getOrder(id: string) {
		return this.http.get(`${this.apiUrl}/app/dispatchOrder/${id}`);
	}

	getWorkers(query: string, unionId: number, date: Date | string = null, dispatchOrderId: string) {
		const requestedDate: moment.Moment = moment(date).set('hours', moment().get('hours')).set('minutes', moment().get('minutes'));
		const params: HttpParams = new HttpParams({
			fromObject: {
				SkipCount: 0,
				MaxResultCount: 30,
				SearchQ: query,
				UnionId: unionId,
				Date: requestedDate.format('YYYY-MM-DDTHH:mm:ss'),
				isActive: true,
				dispatchOrderId: dispatchOrderId
			}
		})

		return this.http.get<any>(`${this.apiUrl}/app/worker/originalDispatchWorkerList`, { params });
	}

	assignJobs(id, jobs) {
		return this.http.put(`${this.apiUrl}/app/dispatchOrder/${id}/assignJobs`, jobs);
	}

	finalizeDispatch(id: string, fromDBD?: boolean) {
		let params: HttpParams = new HttpParams();
		if (fromDBD) {
			params = params.append('isFromDispatchByDate', true);
		}
		return this.http.post(`${this.apiUrl}/app/dispatchOrder/${id}/finalizeDispatch`, null, { params });
	}

	getDispatchOrders(unionId: number) {
		return this.http.get(`${this.apiUrl}/app/dispatchOrder?unionId=${unionId}`);
	}

	getDispatchOrdersList(skipCount: number, maxResult: number, filters?: Filters): any {
		const filterParams = getParamsFromFilters(filters);
		if (this.env.isBrowser)
			return this.http.get(
				`${this.apiUrl}/app/dispatchOrder?MaxResultCount=${maxResult}&SkipCount=${skipCount}${filterParams}`
			);
	}

	assignJobRequest(workers: AssignWorker[]) {
		return this.http.put(`${this.apiUrl}/app/dispatchOrder/assignJobRequest`, workers);
	}

	workerAssignmentOrder(workers: AssignWorker[]) {
		return this.http.put(`${this.apiUrl}/app/dispatchOrder/WorkerAssignmentOrder`, workers);
	}

	deleteJobAssignment(jobAssignmentId: string) {
		return this.http.delete(`${this.apiUrl}/app/dispatchOrder/jobAssignment/${jobAssignmentId}`);
	}

	replaceWorker(
		dispatchOrderId: string,
		dispatchJobRequestId: string,
		workerId: number,
		replacedWorkerId: number,
		hasAgreedCheckCertifications: boolean,
		hasAgreedAssignedSameStartTime: boolean,
		hasAgreedAlreadyAssigned: boolean,
		replacementDate: string,
		isQuitReplacement?: boolean,
	) {
		return this.http.put(`${this.apiUrl}/app/dispatchOrder/replaceWorker`, {
			dispatchOrderId,
			dispatchJobRequestId,
			workerId,
			replacedWorkerId,
			hasAgreedCheckCertifications,
			hasAgreedAssignedSameStartTime,
			hasAgreedAlreadyAssigned,
			replacementDate,
			isQuitReplacement: isQuitReplacement || false
		});
	}

	cancelReplaceWorker(jobAssignmentId: string) {
		return this.http.delete(
			`${this.apiUrl}/app/dispatchOrder/cancelReplaceWorker/${jobAssignmentId}`
		);
	}

	cancelDispatch(id: string, comments: string, SmsMessage: string) {
		return this.http.post(`${this.apiUrl}/app/dispatchOrder/${id}/cancelDispatch`, {
			id: id,
			comments: comments,
			smsMessage: SmsMessage
		});
	}

	updateAssignmentNote(dispatchOrderId: string, workerId: number, notes: string) {
		return this.http.post(`${this.apiUrl}/app/dispatchOrder/workerDispatchNotes`, { dispatchOrderId, workerId, notes });
	}

	publishAllDispatchs(orderId: string) {
		return this.http.post<any>(`${this.apiUrl}/app/dispatchOrder/publishAll/${orderId}`, null);
	}

	checkDispatchEngineRules(workerId: number, unionId: number, dispatchOrderId: string, jobDetailId?: string): Observable<CheckDispatchEngineRulesResult> {
		const payload: CheckDispatchEngineRulesPayload = {
			workerId: workerId,
			unionId: unionId,
			dispatchOrderId: dispatchOrderId
		};
		if (!payload.dispatchOrderId) {
			payload.jobDetailId = jobDetailId;
		}
		return this.http.post<CheckDispatchEngineRulesResult>(`${this.apiUrl}/app/dispatchOrder/checkDispatchEngineRules`, payload);
	}

	bumpWorker(
		dispatchOrderId: string,
		dispatchJobRequestId: string,
		workerId: number,
		bumpedWorkerId: number,
		hasAgreedCheckCertifications: boolean,
		hasAgreedAssignedSameStartTime: boolean,
		hasAgreedAlreadyAssigned: boolean,
		bumpedDate: string
	) {
		return this.http.put(`${this.apiUrl}/app/dispatchOrder/bumpWorker`, {
			dispatchOrderId,
			dispatchJobRequestId,
			workerId,
			bumpedWorkerId,
			hasAgreedCheckCertifications,
			hasAgreedAssignedSameStartTime,
			hasAgreedAlreadyAssigned,
			bumpedDate
		});
	}

	cancelBumpWorker(jobAssignmentId: string) {
		return this.http.post(
			`${this.apiUrl}/app/dispatchOrder/cancelBumpWorker/${jobAssignmentId}`, {}
		);
	}

	async openAssignWorkerErrorDialog(comments: string): Promise<any> {
		const dialogRef = this.dialog.open(AssignWorkerErrorDialogComponent, {
			width: "600px",
			data: {
				info: comments
			}
		});

		return dialogRef.afterClosed()
			.toPromise()
			.then(result => {
				return Promise.resolve(result);
			});

	}
}