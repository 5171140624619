import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "assign-worker-error-dialog",
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule],
	templateUrl: "./assign-worker-error-dialog.component.html"
})
export class AssignWorkerErrorDialogComponent {

	comments: UntypedFormControl = new UntypedFormControl("");

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: {
			info: string
		},
		private dialogRef: MatDialogRef<AssignWorkerErrorDialogComponent>,
	) {}

	closeDialog(): void {
		this.dialogRef.close();
	}

	confirm(): void {
		this.dialogRef.close({
			action: 'confirm',
			data: this.comments.value
		});
	}

}
